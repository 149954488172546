import React from 'react';
import '../works.css';
import Header from '../Header.js'
import Contact from '../Contact.js'
import Footer from '../Footer.js'
import IllusMain from '../images/works/illustrations/illus-main.jpg'
import IllusGinger from '../images/works/illustrations/illus-ginger.jpg'
import IllusGingerArticle from '../images/works/illustrations/illus-ginger-article.jpg'
import IllusElisen from '../images/works/illustrations/illus-elisen.png'
import IllusElisenPackaging from '../images/works/illustrations/illus-elisen-packaging.png'
import IllusVenue from '../images/works/illustrations/illus-venue.jpg'
import IllusHorizontesLatinos from '../images/works/illustrations/illus-horizontes-latinos.jpg'
import IllusCulinary from '../images/works/illustrations/illus-culinary.jpg'
import IllusNatalia from '../images/works/illustrations/illus-natalia.png'
import IllusCollage1 from '../images/works/illustrations/illu-collage-1.jpg'
import IllusCollage2 from '../images/works/illustrations/illu-collage-2.jpg'
import IllusCollage3 from '../images/works/illustrations/illu-collage-3.jpg'
import IllusCollage4 from '../images/works/illustrations/illu-collage-4.jpg'
import IllusCollage5 from '../images/works/illustrations/illu-collage-5.jpg'


function Illustrations() {
	return (
		<div>
	
			<Header />
			<div class="container-fluid works">
			{/* <!-- Control the column width, and how they should appear on different devices --> */}
			<div class="row intro-works row-no-padding">
				{/* <div class="col-sm-1" ></div> */}
				<div class="col-xl-12 d-block d-sm-none"><div id='category'>Series of</div>
					<div id='company'>Illustrations</div>
				</div>
				<div class="col-sm-4 d-none d-sm-block" >
					<div id='category'>Series of</div>
					<div id='company'>Illustrations</div>
					<div className='works-intro-heading'>Role</div>
					<div className='works-intro-details'>Illustrator</div>
					<div className='works-intro-heading'>Client</div>
					<div className='works-intro-details'>Various</div>
					<div className='works-intro-heading'>Expertise</div>
					<div className='works-intro-details'>Illustration /Art Direction</div>
				</div>
				<div class="col-sm-6 d-none d-sm-block" ><img src={IllusMain} alt='kristall-baeder' className='main-image' /></div>
				<div class="row row-no-padding">
					<div class="col-xl-6 d-block d-sm-none" ><img src={IllusMain} alt='kristall-baeder' className='main-image' /></div>
				</div>
				<div class="col-sm-1 d-block d-sm-none" ></div>
				<div class="col-sm-10 d-block d-sm-none" >
					<div className='works-intro-heading'>Role</div>
					<div className='works-intro-details'>Illustrator</div>
					<div className='works-intro-heading'>Client</div>
					<div className='works-intro-details'>Various</div>
					<div className='works-intro-heading'>Expertise</div>
					<div className='works-intro-details'>Illustration /Art Direction</div>
				</div>
				<div class="col-sm-1 d-block d-sm-none" ></div>
				{/* <div class="col-sm-1" ></div> */}
			</div>
			<div class="row">
				
				<div className="col-xl-12 work-description one" id="intro-illu">This is a collection of illustrations I have made in recent years for various projects and clients.</div>
			
			</div>

			<div class="row row-no-padding">
				<div className="col" align="center"><h2 id="h2">Ginger Magazine</h2></div>
			</div>

			<div class="row">
				<div className="col-xl-12 work-description two">“My Six Month German Integration Course” illustration for “Ginger Magazin”.</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images" ><img src={IllusGinger} alt='kristall-baeder-business-card' className='works-image one' id="illu-portrait1"/></div>
			</div>
			<div class="row">
				<div class="col caption one"></div>
			</div>
			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={IllusGingerArticle} alt='kristall-baeder-business-card' className='works-image one' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Article layout</div>
			</div>

			<div class="row row-no-padding">
				<div className="col" align="center"><h2 id="h2">Elisen Lebkuchen</h2></div>
			</div>

			<div class="row">
				<div className="col-xl-12 work-description two nuremberg">Illustration for the packaging of the famous Nuremberg cookies.</div>
			</div>



			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={IllusElisen} alt='kristall-baeder-logo' className='works-image two' /></div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={IllusElisenPackaging} alt='kristall-baeder-magazin' className='works-image three' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Packaging</div>
			</div>

			<div class="row row-no-padding">
				<div className="col d-block d-sm-none" align="center"><h2 id="h2">San Sebastian <br></br>Film Festival</h2></div>
			</div>

			<div class="row row-no-padding">
				<div className="col d-none d-sm-block" align="center"><h2 id="h2">San Sebastian Film Festival</h2></div>
			</div>

			<div class="row">
				<div className="col-xl-12 work-description two">Proposals for three different venues belonging to the 62nd San Sebastian International Film Festival.</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={IllusVenue} alt='kristall-baeder-online' className='works-image four' id="illu-portrait" /></div>
			</div>

			<div class="row">
				<div class="col caption one" id="illu-portrait-caption">Main venue</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={IllusHorizontesLatinos} alt='kristall-baeder-online' className='works-image four' id="illu-portrait"/></div>
			</div>

			<div class="row">
				<div class="col caption one" id="illu-portrait-caption">Horizontes latinos</div>
			</div>

			
			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={IllusCulinary} alt='kristall-baeder-online' className='works-image four' id="illu-portrait" /></div>
			</div>

			<div class="row">
				<div class="col caption one" id="illu-portrait-caption">Culinary Zinema</div>
			</div>
			

			<div class="row row-no-padding">
				<div className="col" align="center"><h2 id="h2">Natalia Ensemble</h2></div>
			</div>

			<div class="row">
				<div className="col-xl-12 work-description two">Proposal for the Natalia Ensemble “Mahler Symphony No. 5” CD cover.</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={IllusNatalia} alt='kristall-baeder-online' className='works-image four' /></div>
			</div>

			<div class="row row-no-padding">
				<div className="col" align="center"><h2 id="h2">Collages</h2></div>
			</div>

			<div class="row">
				<div className="col-xl-12 work-description two">Collection of paper collages I have made in the past years.</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={IllusCollage1} alt='kristall-baeder-online' className='works-image four' id="illu-portrait"/></div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={IllusCollage2} alt='kristall-baeder-online' className='works-image four' id="illu-portrait"/></div>
			</div>
			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={IllusCollage3} alt='kristall-baeder-online' className='works-image four' id="illu-portrait"/></div>
			</div>
			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={IllusCollage4} alt='kristall-baeder-online' className='works-image four' id="illu-portrait"/></div>
			</div>
			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={IllusCollage5} alt='kristall-baeder-online' className='works-image four'id="illu-portrait"/></div>
			</div>
			<div class="row">
				<div class="col caption one"></div>
			</div>


			<p className='sidebar-works d-none d-sm-block'>Illustrations</p>

			<div class="row navigation main">
				<div class="col-6 text-right">
					<a href='/mycs' className='no-style-anchor'>
						<div className='prev navi'>Previous</div>
						<div className='prev goto' id="no-style-at-all-anchor">Mycs</div>
					</a>
				</div>


				<div class="col-6 navigation d-block d-sm-none">
					<a href='/rupp-hubrach' className='no-style-anchor'>
						<div className='next navi'>Next</div>
						<div className='next goto' id="no-style-at-all-anchor" >R+H</div>
					</a>
				</div>
				
				<div class="col-6 navigation d-none d-sm-block">
					<a href='/rupp-hubrach' className='no-style-anchor'>
						<div className='next navi'>Next</div>
						<div className='next goto' id="no-style-at-all-anchor" >Rupp + Hubrach</div>
					</a>
				</div>
			</div>

			<Contact />
			
			<div>
				<Footer />
			</div>
		</div >
		</div>
	);
}

export default Illustrations;