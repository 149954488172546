import React from 'react';
import '../works.css';
import Header from '../Header.js'
import Contact from '../Contact.js'
import Footer from '../Footer.js'
import binetiqMain from '../images/works/binetiq/binetiq-main.jpg'
import binetiqPoster from '../images/works/binetiq/binetiq-poster.jpg'
import binetiqBusinessCard from '../images/works/binetiq/binetiq-business-card.jpg'
import binetiqStationary from '../images/works/binetiq/binetiq-stationary.jpg'
import binetiqWebsite from '../images/works/binetiq/binetiq-website.jpg'


function Binetiq() {
	return (
		<div>
	
			<Header />
			<div class="container-fluid works">
			{/* <!-- Control the column width, and how they should appear on different devices --> */}
			<div class="row intro-works row-no-padding">
				{/* <div class="col-sm-1" ></div> */}
				<div class="col-xl-12 d-block d-sm-none"><div id='category'>Naturopathic practice</div>
					<div id='company'>Binetiq</div>
				</div>
				<div class="col-sm-4 d-none d-sm-block" >
					<div id='category'>Naturopathic practice</div>
					<div id='company'>Binetiq</div>
					<div className='works-intro-heading'>Role</div>
					<div className='works-intro-details'>Art Director and Illustrator by FYFF GmbH</div>
					<div className='works-intro-heading'>Client</div>
					<div className='works-intro-details'>Binetiq</div>
					<div className='works-intro-heading'>Expertise</div>
					<div className='works-intro-details'>Logo Design, Rebranding, Illustration</div>
				</div>
				<div class="col-sm-6 d-none d-sm-block" ><img src={binetiqMain} alt='kristall-baeder' className='main-image' /></div>
				<div class="row row-no-padding">
					<div class="col-xl-6 d-block d-sm-none" ><img src={binetiqMain} alt='kristall-baeder' className='main-image' /></div>
				</div>
				<div class="col-sm-1 d-block d-sm-none" ></div>
				<div class="col-sm-10 d-block d-sm-none" >
					<div className='works-intro-heading'>Role</div>
					<div className='works-intro-details'>Art Director and Illustrator by FYFF GmbH</div>
					<div className='works-intro-heading'>Client</div>
					<div className='works-intro-details'>Binetiq</div>
					<div className='works-intro-heading'>Expertise</div>
					<div className='works-intro-details'>Logo Design, Rebranding, Illustration</div>
				</div>
				<div class="col-sm-1 d-block d-sm-none" ></div>
				{/* <div class="col-sm-1" ></div> */}
			</div>
			<div class="row">
				
				<div className="col-xl-12 work-description one">Proposal for this naturopathic practice towards the establishment of a new brand identity. I also created the textures/illustrations.</div>
			
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={binetiqPoster} alt='kristall-baeder-business-card' className='works-image one' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Poster</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={binetiqBusinessCard} alt='kristall-baeder-logo' className='works-image two' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Business cards</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={binetiqStationary} alt='kristall-baeder-magazin' className='works-image three' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Stationary</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={binetiqWebsite} alt='kristall-baeder-online' className='works-image four' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Website look & feel</div>
			</div>



			<p className='sidebar-works d-none d-sm-block'>Binetiq</p>

			<div class="row navigation main">
				<div class="col-6 text-right">
					<a href='/amano' className='no-style-anchor'>
						<div className='prev navi'>Previous</div>
						<div className='prev goto' id="no-style-at-all-anchor">A mano</div>
					</a>
				</div>
				<div class="col-6 navigation">
					<a href='/galerie-voigt' className='no-style-anchor'>
						<div className='next navi'>Next</div>
						<div className='next goto' id="no-style-at-all-anchor" >Galerie Voigt</div>
					</a>
				</div>
			</div>

			<Contact />
			
			<div>
				<Footer />
			</div>
		</div >
			</div>
	);
}

export default Binetiq;