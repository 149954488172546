import React from 'react';
import './index.css';
import './header.css';

function Menu(props) {

	const toggle = () => {
		props.toggle()
	}

	return (
		<div id='open-menu'>
			<div onClick={toggle} className='menu-about'><a href='https://veronicacolombi.com/#about'>About</a></div>
			<div onClick={toggle} className='menu-works'><a href='https://veronicacolombi.com/#h2-latest-works'>Works</a></div>
			<div onClick={toggle} className='menu-contact'><a href='https://veronicacolombi.com/#get-in-touch'>Contact</a></div>
			<div onClick={toggle} className='menu-social'><a target="_blank" href='https://www.instagram.com/whosvero/'><p>Instagram</p></a><a target="_blank" href='https://www.linkedin.com/in/veronica-colombi-6a982161/'><p>LinkedIn</p></a></div>
		</div>
	)
}

export default Menu;
