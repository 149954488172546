import React from 'react';
import './index.css';
import './contact.css';


function Contact() {
	return (
		<div class="container contact">
			<div class="row">
				{/* <div class="col-xl-2" ></div> */}
				<div class="col-xl-12" align="center"><h2 id='get-in-touch'>Get in touch</h2></div>
				{/* <div class="col-xl-2" ></div> */}
			</div>
			<div class="row">
				{/* <div class="col-xl-2" ></div> */}
				<div class="col-xl-12" id="collaborations" >I am always open to collaborations and inspiring projects. If you’d like to discuss an idea, or simply get in touch, please drop me a line.</div>
				{/* <div class="col-xl-2" ></div> */}
			</div>
			<div class="row">
				{/* <div class="col-xl-2" ></div> */}
				<div class="col-xl-12" id="contact-me" >email: veronica.colombi@gmail.com <br></br>social media: <a id='linkedin-contact' target="_blank" href='https://www.linkedin.com/in/veronica-colombi-6a982161/'>linkedin</a> <a id='instagram-contact' target="_blank" href='https://www.instagram.com/whosvero/'>instagram</a></div>
				{/* <div class="col-xl-2" ></div> */}
			</div>
		</div>
	);
}

export default Contact;