import React from 'react';
import './footer.css';
import arrow from './images/arrow.svg';

function Footer() {

	function scrollToTop() {
		window.scrollTo({ top: 0, behavior: "smooth" })
	}

	return (
		<footer class="container" >
			<div class="row" id='up'>
				
				<div class="col back-to-top text-right" ><span onClick={scrollToTop} className='textup'>Back to top</span>

					<svg onClick={scrollToTop} className='arrow' width="15" height="33" viewBox="0 0 15 33" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g clip-path="url(#clip0)">
							<path id="upper" d="M7.59961 1.3999V32.7999" stroke="black" stroke-width="2" stroke-miterlimit="10" />
							<path id="line" d="M14 7.7999L7.5 1.3999L1 7.7999" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
						</g>
						<defs>
							<clipPath id="clip0">
								<rect width="15" height="32.8" fill="white" />
							</clipPath>
						</defs>
					</svg>

				</div>
			</div>
			<div class="row">
				<div class="col-xl-12 col-sm-12 created-by" >© Veronica Colombi 2020</div>
			</div>

		</footer>
	);
}

export default Footer;