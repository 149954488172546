import React from 'react';
import '../works.css';
import Header from '../Header.js'
import Contact from '../Contact.js'
import Footer from '../Footer.js'
import ruppHubrach from '../images/works/rupp/rupp-macbook.jpg'
import ruppBusinessCard from '../images/works/rupp/rupp-business-card.jpg'
import ruppBusinessCard2 from '../images/works/rupp/rupp-business-card-2.jpg'
import ruppTwo from '../images/works/rupp/rupp-two.jpg'
import ruppFullPicture from '../images/works/rupp/rupp-full-picture.jpg'
import ruppFullPicture2 from '../images/works/rupp/rupp-full-picture2.jpg'
import ruppIcons from '../images/works/rupp/rupp-icons.jpg'
import ruppTransition from '../images/works/rupp/rupp-transition-lense.jpg'
import ruppSun from '../images/works/rupp/rupp-sun.jpg'
import ruppSport from '../images/works/rupp/rupp-sport.jpg'
import ruppSeeTest from '../images/works/rupp/rupp-see-test.jpg'
import ruppOpticianSearch from '../images/works/rupp/rupp-optician-search.jpg'
import ruppProgressive from '../images/works/rupp/rupp-progressive.jpg'
import ruppSellerSearch from '../images/works/rupp/rupp-seller-search.jpg'
import ruppSportLense from '../images/works/rupp/rupp-sport-lense.jpg'


function Rupp() {
	return (
		<div>
	
			<Header />
			<div class="container-fluid works">
			{/* <!-- Control the column width, and how they should appear on different devices --> */}
			<div class="row intro-works row-no-padding">
				{/* <div class="col-sm-1" ></div> */}
				<div class="col-xl-12 d-block d-sm-none"><div id='category'>Optical lenses</div>
					<div id='company'>R+H</div>
				</div>
				<div class="col-sm-4 d-none d-sm-block" >
					<div id='category'>Optical lenses</div>
					<div id='company'>Rupp+Hubrach</div>
					<div className='works-intro-heading'>Role</div>
					<div className='works-intro-details'>UX/UI Designer bei DKdL GmbH</div>
					<div className='works-intro-heading'>Client</div>
					<div className='works-intro-details'>R+H GmbH</div>
					<div className='works-intro-heading'>Expertise</div>
					<div className='works-intro-details'>UX/UI</div>
					<div className='works-intro-details website' id='website'><a href='https://www.rh-brillenglas.de/' target="blank">See website</a></div>
				</div>
				<div class="col-sm-6 d-none d-sm-block" ><img src={ruppHubrach} alt='kristall-baeder' className='main-image rupp' /></div>
				<div class="row row-no-padding">
					<div class="col-xl-6 d-block d-sm-none" ><img src={ruppHubrach} alt='kristall-baeder' className='main-image' /></div>
				</div>
				<div class="col-sm-1 d-block d-sm-none" ></div>
				<div class="col-sm-10 d-block d-sm-none" >
					<div className='works-intro-heading'>Role</div>
					<div className='works-intro-details'>UX/UI Designer bei DKdL GmbH</div>
					<div className='works-intro-heading'>Client</div>
					<div className='works-intro-details'>R+H GmbH</div>
					<div className='works-intro-heading'>Expertise</div>
					<div className='works-intro-details'>UX/UI</div>
					<div className='works-intro-heading'></div>
					<div className='works-intro-details website'><a href='https://www.rh-brillenglas.de/' target="blank">See website</a></div>
				</div>
				<div class="col-sm-1 d-block d-sm-none" ></div>
				{/* <div class="col-sm-1" ></div> */}
			</div>
			<div class="row">
				
				<div className="col-xl-12 work-description one">Together with the Dkdl UX/UI department I designed a fully responsive website based on WYSIWYG technology.</div>
			
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={ruppBusinessCard} alt='kristall-baeder-business-card' className='works-image one' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Five box menu</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={ruppBusinessCard2} alt='kristall-baeder-logo' className='works-image two' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Four box menu</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={ruppTwo} alt='kristall-baeder-magazin' className='works-image three' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Two box menu</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={ruppFullPicture} alt='kristall-baeder-online' className='works-image four' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Full width picture</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={ruppFullPicture2} alt='kristall-baeder-icons' className='works-image five' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Full width picture</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={ruppIcons} alt='kristall-baeder-icons' className='works-image six' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Icons</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={ruppTransition} alt='kristall-baeder-merchandise' className='works-image seven' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Transition lens tool</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={ruppSun} alt='kristall-baeder-merchandise' className='works-image seven' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Sun lens tool</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={ruppSport} alt='kristall-baeder-merchandise' className='works-image seven' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Sport lens tool</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={ruppSeeTest} alt='kristall-baeder-merchandise' className='works-image seven' /></div>
			</div>

			<div class="row">
				<div class="col caption one">See test</div>
			</div>


			
			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={ruppOpticianSearch} alt='kristall-baeder-merchandise' className='works-image seven' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Optician search</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={ruppProgressive} alt='kristall-baeder-merchandise' className='works-image seven' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Progressive lens explanation</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={ruppSellerSearch} alt='kristall-baeder-merchandise' className='works-image seven' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Seller search</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={ruppSportLense} alt='kristall-baeder-merchandise' className='works-image seven' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Sport lens options</div>
			</div>


			<p className='sidebar-works d-none d-sm-block'>Rupp+Hubrach</p>

			<div class="row navigation main">
				<div class="col-6 text-right">
					<a href='/illustrations' className='no-style-anchor'>
						<div className='prev navi'>Previous</div>
						<div className='prev goto' id="no-style-at-all-anchor">Illustrations</div>
					</a>
				</div>
				<div class="col-6 navigation">
					<a href='/kristall-baeder' className='no-style-anchor'>
						<div className='next navi'>Next</div>
						<div className='next goto' id="no-style-at-all-anchor" >Kristall Bäder</div>
					</a>
				</div>
			</div>

			<Contact />
			
			<div>
				<Footer />
			</div>
		</div >
		</div>
	);
}

export default Rupp;