import React from 'react';
import '../works.css';
import Header from '../Header.js'
import Contact from '../Contact.js'
import Footer from '../Footer.js'
import kristallBaeder from '../images/works/kristall-baeder/kristall-baeder.jpg'
import kristallBaederBusinessCard from '../images/works/kristall-baeder/kristall-baeder-business-card.jpg'
import kristallBaederLogo from '../images/works/kristall-baeder/kristall-baeder-logo.jpg'
import kristallBaederMagazin from '../images/works/kristall-baeder/kristall-baeder-magazin.jpg'
import kristallBaederOnline from '../images/works/kristall-baeder/kristall-baeder-online.jpg'
import kristallBaederIcons from '../images/works/kristall-baeder/kristall-baeder-icons.svg'
import kristallBaederLotion from '../images/works/kristall-baeder/kristall-baeder-lotion.jpg'
import kristallBaederMerchandise from '../images/works/kristall-baeder/kristall-baeder-merchandise.jpg'

function KristallBaeder() {
	return (
		<div>
	
			<Header />
			<div class="container-fluid works">
			{/* <!-- Control the column width, and how they should appear on different devices --> */}
			<div class="row intro-works row-no-padding">
				{/* <div class="col-sm-1" ></div> */}
				<div class="col-xl-12 d-block d-sm-none"><div id='category'>German Spa</div>
					<div id='company'>Kristall Bäder</div>
				</div>
				<div class="col-sm-4 d-none d-sm-block" >
					<div id='category'>German Spa</div>
					<div id='company'>Kristall Bäder</div>
					<div className='works-intro-heading'>Role</div>
					<div className='works-intro-details'>Art Director by FYFF GmbH</div>
					<div className='works-intro-heading'>Client</div>
					<div className='works-intro-details'>Kristall Bäder</div>
					<div className='works-intro-heading'>Expertise</div>
					<div className='works-intro-details'>Logo Design, Rebranding, Editorial Design, Illustration</div>
				</div>
				<div class="col-sm-8 d-none d-sm-block" ><img src={kristallBaeder} alt='kristall-baeder' className='main-image' /></div>
				<div class="row row-no-padding">
					<div class="col-xl-6 d-block d-sm-none" ><img src={kristallBaeder} alt='kristall-baeder' className='main-image' /></div>
				</div>
				<div class="col-sm-1 d-block d-sm-none" ></div>
				<div class="col-sm-10 d-block d-sm-none" >
					<div className='works-intro-heading'>Role</div>
					<div className='works-intro-details'>Art Director by FYFF GmbH</div>
					<div className='works-intro-heading'>Client</div>
					<div className='works-intro-details'>Kristall Bäder</div>
					<div className='works-intro-heading'>Expertise</div>
					<div className='works-intro-details'>Logo Design, Rebranding, Editorial Design, Illustration</div>
				</div>
				<div class="col-sm-1 d-block d-sm-none" ></div>
				{/* <div class="col-sm-1" ></div> */}
			</div>

			<div class="row">
				
				<div className="col-xl-12 work-description one">Proposal for the rebranding of the German spa chain. I also designed logos and illustrations.</div>
			
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={kristallBaederBusinessCard} alt='kristall-baeder-business-card' className='works-image one' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Stationary</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={kristallBaederLogo} alt='kristall-baeder-logo' className='works-image two' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Location logos</div>
			</div>
			<div class="row row-no-padding">
				<div className="col" align="center"><h2 id="h2">Rebranding</h2></div>
			</div>

			<div class="row">
				<div className="col-xl-12 work-description two">The rebranding includes a company magazine layout, a proposal for the website look & feel, a serie of icons to use for eventual internal signs, packaging for beauty products, as well as the logo and corporate identity of the spa restaurant.</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={kristallBaederMagazin} alt='kristall-baeder-magazin' className='works-image three' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Magazine</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={kristallBaederOnline} alt='kristall-baeder-online' className='works-image four' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Website look & feel</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={kristallBaederIcons} alt='kristall-baeder-icons' className='works-image five' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Icons</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={kristallBaederLotion} alt='kristall-baeder-icons' className='works-image six' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Beauty products</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={kristallBaederMerchandise} alt='kristall-baeder-merchandise' className='works-image seven' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Restaurant image</div>
			</div>


			<p className='sidebar-works d-none d-sm-block'>Kristall Bäder</p>


			<div class="row navigation main">

			<div class="col-6 navigation d-block d-sm-none text-right">
					<a href='/rupp-hubrach' className='no-style-anchor'>
						<div className='prev navi'>Previous</div>
						<div className='prev goto' id="no-style-at-all-anchor" >R+H</div>
					</a>
				</div>


				<div class="col-6 navigation d-none d-sm-block text-right">
					<a href='/rupp-hubrach' className='no-style-anchor'>
						<div className='prev navi'>Previous</div>
						<div className='prev goto' id="no-style-at-all-anchor" >Rupp + Hubrach</div>
					</a>
				</div>
				<div class="col-6 navigation">
					<a href='/amano' className='no-style-anchor'>
						<div className='next navi'>Next</div>
						<div className='next goto' id="no-style-at-all-anchor" >A mano</div>
					</a>
				</div>
			</div>

			<Contact />
			
			<div>
				<Footer />
			</div>
		</div >
		</div >
	);
}

export default KristallBaeder;