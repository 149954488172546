import React from 'react';
import '../works.css';
import Header from '../Header.js'
import Contact from '../Contact.js'
import Footer from '../Footer.js'
import loftwerkMain from '../images/works/amano/amano-main.png'
import loftwerkHome from '../images/works/amano/amano-1.jpg'
import loftwerkTypo from '../images/works/amano/amano-2.jpg'
import loftwerkEvents from '../images/works/amano/amano-3.jpg'
import loftwerkLocation from '../images/works/amano/amano-4.jpg'
import loftwerkPrivate from '../images/works/amano/1200.png'


function Amano() {
	return (
		<div>
	
			<Header />
			<div class="container-fluid works">
			{/* <!-- Control the column width, and how they should appear on different devices --> */}
			<div class="row intro-works row-no-padding">
				{/* <div class="col-sm-1" ></div> */}
				<div class="col-xl-12 d-block d-sm-none"><div id='category'>Food e-commerce</div>
					<div id='company'>A mano</div>
				</div>
				<div class="col-sm-4 d-none d-sm-block" >
					<div id='category'>Food e-commerce</div>
					<div id='company'>A mano</div>
					<div className='works-intro-heading'>Role</div>
					<div className='works-intro-details'>Art Director & UX/UI Designer</div>
					<div className='works-intro-heading'>Client</div>
					<div className='works-intro-details'>A mano</div>
					<div className='works-intro-heading'>Expertise</div>
					<div className='works-intro-details'>Logo Design, Branding, Service Design, UX/UI Design</div>
					<div className='works-intro-details website'><a href='https://www.figma.com/proto/cMSDAE4bSIp8Ng7ppVlWZ4/A-Mano?node-id=221%3A702&scaling=min-zoom' target="blank">See (work in progress) prototype</a></div>
				</div>
				<div class="col-sm-6 d-none d-sm-block" ><img src={loftwerkMain} alt='kristall-baeder' className='main-image' /></div>
				<div class="row row-no-padding">
					<div class="col-xl-6 d-block d-sm-none" ><img src={loftwerkMain} alt='kristall-baeder' className='main-image' /></div>
				</div>
				<div class="col-sm-1 d-block d-sm-none" ></div>
				<div class="col-sm-10 d-block d-sm-none" >
					<div className='works-intro-heading'>Role</div>
					<div className='works-intro-details'>Art Director & UX/UI Designer</div>
					<div className='works-intro-heading'>Client</div>
					<div className='works-intro-details'>A mano</div>
					<div className='works-intro-heading'>Expertise</div>
					<div className='works-intro-details'>Logo Design, Branding, Service Design, UX/UI Design</div>
					<div className='works-intro-details website'><a href='https://www.figma.com/proto/cMSDAE4bSIp8Ng7ppVlWZ4/A-Mano?node-id=221%3A702&scaling=min-zoom' target="blank">See (work in progress) prototype</a></div>
				</div>
				<div class="col-sm-1 d-block d-sm-none" ></div>
				{/* <div class="col-sm-1" ></div> */}
			</div>
			<div class="row">
				
				<div className="col-xl-12 work-description one" id="intro-illu">A mano is an e-commerce platform where people can buy fresh homemade pasta within only a couple of clicks.</div>
			
			</div>

			<div class="row row-no-padding">
				<div className="col" align="center"><h2 id="h2">My role</h2></div>
			</div>

			<div class="row">
				<div className="col-xl-12 work-description two">In this project I covered different roles as designer, ranging from Branding, Service Design Meethods/Workshops to Prototyping and Deliveries.</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={loftwerkHome} alt='kristall-baeder-business-card' className='works-image one' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Results of interviews with the client in Miro</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={loftwerkTypo} alt='kristall-baeder-logo' className='works-image two' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Sketching the new layout ideas</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={loftwerkEvents} alt='kristall-baeder-magazin' className='works-image three' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Sitemap</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={loftwerkLocation} alt='kristall-baeder-online' className='works-image four' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Final wireframes</div>
			</div>

			<div class="row row-no-padding">
				<div className="col" align="center"><h2 id="h2">The Goals</h2></div>
			</div>

			<div class="row">
				<div className="col-xl-12 work-description two nuremberg">According to our reasearch, we decided to create an app that is easy and comfortable to interact with, and at the same time, gives the feeling of authenticity and quality of products</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={loftwerkPrivate} alt='kristall-baeder-online' className='works-image four' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Mockups</div>
			</div>




			<p className='sidebar-works d-none d-sm-block'>A mano</p>

			<div class="row navigation main">
				<div class="col-6 text-right">
					<a href='/kristall-baeder' className='no-style-anchor'>
						<div className='prev navi'>Previous</div>
						<div className='prev goto' id="no-style-at-all-anchor">Kristall Bäder</div>
					</a>
				</div>
				<div class="col-6 navigation">
					<a href='/binetiq' className='no-style-anchor'>
						<div className='next navi'>Next</div>
						<div className='next goto' id="no-style-at-all-anchor" >Binetiq</div>
					</a>
				</div>
			</div>

			<Contact />
			
			<div>
				<Footer />
			</div>
		</div >
		</div>
	);
}

export default Amano;