import React, { useState } from 'react';
import './index.css';
import vero from './images/vero.svg';
import Header from './Header.js'
import Contact from './Contact.js'
import Footer from './Footer.js'
import prevKristall from './images/prev/prev-kristall-baeder.svg';
import prevRupp from './images/prev/prev-rupp.svg';
import prevAmano from './images/prev/prev-amano.svg';
import prevBinetiq from './images/prev/prev-binetiq.svg';
import prevVoigt from './images/prev/prev-voigt.svg';
import prevMycs from './images/prev/prev-mycs.svg';
import prevIllus from './images/prev/prev-illus.svg';

function Home() {
	const [hoveredRupp, setHoveredRupp] = useState(false);
	const [hoveredKristall, setHoveredKristall] = useState(false);
	const [hoveredLoft, setHoveredLoft] = useState(false);
	const [hoveredBinetiq, setHoveredBinetiq] = useState(false);
	const [hoveredVoigt, setHoveredVoigt] = useState(false);
	const [hoveredMycs, setHoveredMycs] = useState(false);
	const [hoveredIllus, setHoveredIllus] = useState(false);

	const toggleHoverRupp = () => setHoveredRupp(!hoveredRupp);
	const toggleHoverKristall = () => setHoveredKristall(!hoveredKristall);
	const toggleHoverLoft = () => setHoveredLoft(!hoveredLoft);
	const toggleHoverBinetiq = () => setHoveredBinetiq(!hoveredBinetiq);
	const toggleHoverVoigt = () => setHoveredVoigt(!hoveredVoigt);
	const toggleHoverMycs = () => setHoveredMycs(!hoveredMycs);
	const toggleHoverIllus = () => setHoveredIllus(!hoveredIllus);

	return (
		<div>
			<Header />
			<div class="container">
				<div class="row intro intro1 row-no-padding">
					<div className="col-12">

						<span id='veronica'>Veronica </span><span> is a <a id='visualux' href='#expertise'>Visual and Ux Designer</a> from Italy, currently based in Zurich.</span></div>
				</div>



				<div class="row row-no-padding veronicaportrait">
					<div className="col-12">
						<div class="hovereffect">

							{/* <div class="hovereffect"> */}
							<img class="img-responsive" src={vero} alt="" />
							{/* </div> */}
						</div>
						{/* <div class="veronicaportrait">
				<img src={vero} alt='portrait-veronica-colombi' className='portrait' />
				</div> */}
					</div>
				</div>









				{/* <div class="row">
					<img src={vero} alt='portrait-veronica-colombi' className='portrait' />
				</div> */}
				<div class="row">
					<div className="col" align="center"><h2 id='about' align="center">About</h2></div>
				</div>

				<div class="row intro row-no-padding">
					<div className="col-12">
						In the past 10 years I have been working for digital design agencies and product companies supporting international cross-functional teams.
			</div>
					<div className="col-12 about-second">
						I work combining a user centered approach with my strong visual design background to create user experiences which are relevant, usable and enjoyable.
			</div>
				</div>
				<div class="my-facts">
					<div class="row row-no-padding">
						<div className="col-12" id='expertise'>Main skills</div>
					</div>
					<div class="row work-details row-no-padding">
						<div class="col-lg-3"></div>
						<div class="col-lg-9">
							Visual Design, UX-UI Design, Brand Design, Corporate Design, Editorial Design, Illustration
					</div>
					</div>
					<div class="row row-no-padding">
						<div className="col-12 complementary" >Complementary</div>
					</div>
					<div class="row work-details row-no-padding">
						<div class="col-lg-3"></div>
						<div class="col-lg-9">User research, User testing, Service design, Persona, Wireframing, Prototyping, Agile UX Process, Photography
				</div>
					</div>
				</div>
				{/* <div class="row button">
					<div className="col-12">
						<a className="download-button" href='https://cv-veronica-colombi.s3.eu-central-1.amazonaws.com/cv-veronica-colombi.pdf'>VD Donloa</a>
					</div>
				</div> */}
				{/* <div class="row button">
				<div className="col">
				<a target="_blank" href='http://cv-veronica-colombi.s3-website.eu-central-1.amazonaws.com/cv-veronica-colombi.pdf'><button className="download-button" type="button">CV Download</button></a>
			</div>
			</div> */}

				<div class="row button">
					<button type="button"><a className="download-button" href='https://cv-veronica-colombi.s3.eu-central-1.amazonaws.com/cv-veronica-colombi.pdf'>CV Download</a></button>
				</div>

				<div class="row">
					<div className="col">
						<h2 id="h2-latest-works">Latest Works</h2>
					</div>
				</div>
				<img src={prevKristall} className={hoveredKristall ? 'hovered' : ''} alt='prev-kristall' id='prev-kristall' />
				<img src={prevRupp} className={hoveredRupp ? 'hovered' : ''} alt='prev-rupp' id='prev-rupp' />
				<img src={prevAmano} className={hoveredLoft ? 'hovered' : ''} alt='prev-loftwerk' id='prev-loftwerk' />
				<img src={prevBinetiq} className={hoveredBinetiq ? 'hovered' : ''} alt='prev-binetiq' id='prev-binetiq' />
				<img src={prevVoigt} className={hoveredVoigt ? 'hovered' : ''} alt='prev-voigt' id='prev-voigt' />
				<img src={prevMycs} className={hoveredMycs ? 'hovered' : ''} alt='prev-mycs' id='prev-mycs' />
				<img src={prevIllus} className={hoveredIllus ? 'hovered' : ''} alt='prev-illus' id='prev-illus' />

				<div class="row" id='latest-works'>
					<div class='row row1'>
						<div class="col-sm-12 col-lg-6" id='rupp'>
							<p onMouseEnter={toggleHoverRupp} onMouseLeave={toggleHoverRupp}><a className="no-style-anchor" href='/rupp-hubrach'>Rupp+Hubrach</a></p></div>

						<div class="col-sm-12 col-lg-6" id='kristall'>
							<p id='kristall-baeder' onMouseEnter={toggleHoverKristall} onMouseLeave={toggleHoverKristall}><a className="no-style-anchor" href='/kristall-baeder'>Kristall Bäder</a></p></div>

					</div>
					<div class='row row1'>
						{/* <div class="col-lg-1"></div> */}
						<div class="col-sm-12 col-lg-3" id='loftwerk'>
							<p onMouseEnter={toggleHoverLoft} onMouseLeave={toggleHoverLoft}> <a className="no-style-anchor" href='/amano'>A mano</a></p></div>


						<div class="col-sm-12 col-lg-4" id='binetiq'>
							<p onMouseEnter={toggleHoverBinetiq} onMouseLeave={toggleHoverBinetiq}><a className="no-style-anchor" href='/binetiq'>Binetiq</a></p></div>

						<div class="col-sm-12 col-lg-5" id='galerie-voigt'>
							<p onMouseEnter={toggleHoverVoigt} onMouseLeave={toggleHoverVoigt}><a className="no-style-anchor" href='/galerie-voigt'>Galerie Voigt</a></p></div>
					</div>
					<div class='row row1'>
						<div class="col-sm-12 col-lg-4" id='mycs'>
							<p onMouseEnter={toggleHoverMycs} onMouseLeave={toggleHoverMycs}><a className="no-style-anchor" href='/mycs'>Mycs</a></p></div>

						<div class="col-sm-12 col-lg-8" id='illustrations'>
							<p onMouseEnter={toggleHoverIllus} onMouseLeave={toggleHoverIllus}><a className="no-style-anchor" href='/illustrations'>Illustrations</a></p></div>
					</div>
				</div>
				<Contact />
				<div class="row">
					<div class="col"><h2 id='colophon'>Colophon</h2>	</div>
				</div>

				<div class="my-facts">
					<div class="row colophon-headings row-no-padding">
						<div className="col-12" id='design'>Design</div>
					</div>
					<div class="row work-details row-no-padding">
						<div class="col-lg-3"></div>
						<div class="col-lg-9" id='concept'>
							Concept, CI, UX-UI Design & Photography: Veronica Colombi
					</div>
					</div>
					<div class="row colophon-headings row-no-padding">
						<div className="col-12" id='development'>Development</div>
					</div>
					<div class="row work-details row-no-padding">
						<div class="col-lg-3"></div>
						<div class="col-lg-9" id='developer'>
							Lola Bach
					</div>
					</div>
					<div class="row colophon-headings row-no-padding">
						<div className="col-12" id='copywriting'>Copywriting</div>
					</div>
					<div class="row work-details row-no-padding">
						<div class="col-lg-3"></div>
						<div class="col-lg-9" id='copywriter'>
							Veronica Colombi, Mariel Macia,<br></br>
						Ludovica Ferrari-Cesena & Milena Salci.
					</div>
					</div>
					<div class="row colophon-headings row-no-padding">
						<div className="col-12" id='typography'>Typography</div>
					</div>
					<div class="row work-details row-no-padding">
						<div class="col-lg-3"></div>
						<div class="col-lg-9" id='font'>
							Favorit Std & PT Serif
					</div>
					</div>
					<div class="row colophon-headings row-no-padding">
						<div className="col-12" id='technology'>Technology</div>
					</div>
					<div class="row work-details row-no-padding">
						<div class="col-lg-3"></div>
						<div class="col-lg-9" id='react'>
							React
					</div>
					</div>
					<div class="row colophon-headings row-no-padding">
						<div className="col-12" id='moral-support'>Moral Support</div>
					</div>
					<div class="row work-details row-no-padding">
						<div class="col-lg-3"></div>
						<div class="col-lg-9" id='supporters'>
							Olivia, daughter<br></br> Esteban, husband<br></br>  Lotte & Michele, cats
					</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}

export default Home;
