import React from 'react';
import '../works.css';
import Header from '../Header.js'
import Contact from '../Contact.js'
import Footer from '../Footer.js'
import GalerieVoigtMain from '../images/works/galerievoigt/galerie-voigt-main.jpg'
import GalerieVoigtHighlights from '../images/works/galerievoigt/galerie-voigt-highlights.jpg'
import GalerieVoigtShop from '../images/works/galerievoigt/galerie-voigt-shop.jpg'
import GalerieVoigtCart from '../images/works/galerievoigt/galerie-voigt-cart.jpg'
import GalerieVoigtCheckout from '../images/works/galerievoigt/galerie-voigt-checkout.jpg'


function GalerieVoigt() {
	return (
		<div>
	
			<Header />
			<div class="container-fluid works">
			{/* <!-- Control the column width, and how they should appear on different devices --> */}
			<div class="row intro-works row-no-padding">
				{/* <div class="col-sm-1" ></div> */}
				<div class="col-xl-12 d-block d-sm-none"><div id='category'>Jewelry e-commerce</div>
					<div id='company'>Galerie Voigt</div>
				</div>
				<div class="col-sm-4 d-none d-sm-block" >
					<div id='category'>Jewelry e-commerce</div>
					<div id='company'>Galerie Voigt</div>
					<div className='works-intro-heading'>Role</div>
					<div className='works-intro-details'>UX/UI Designer by FYFF GmbH</div>
					<div className='works-intro-heading'>Client</div>
					<div className='works-intro-details'>Galerie Voigt</div>
					<div className='works-intro-heading'>Expertise</div>
					<div className='works-intro-details'>UX/UI design</div>
				</div>
				<div class="col-sm-6 d-none d-sm-block" ><img src={GalerieVoigtMain} alt='kristall-baeder' className='main-image' /></div>
				<div class="row row-no-padding">
					<div class="col-xl-6 d-block d-sm-none" ><img src={GalerieVoigtMain} alt='kristall-baeder' className='main-image' /></div>
				</div>
				<div class="col-sm-1 d-block d-sm-none" ></div>
				<div class="col-sm-10 d-block d-sm-none" >
					<div className='works-intro-heading'>Role</div>
					<div className='works-intro-details'>UX/UI Designer by FYFF GmbH</div>
					<div className='works-intro-heading'>Client</div>
					<div className='works-intro-details'>Galerie Voigt</div>
					<div className='works-intro-heading'>Expertise</div>
					<div className='works-intro-details'>UX/UI design</div>
				</div>
				<div class="col-sm-1 d-block d-sm-none" ></div>
				{/* <div class="col-sm-1" ></div> */}
			</div>
			<div class="row">
				
				<div className="col-xl-12 work-description one">Proposal for the new e-commerce website concept of Galerie Voigt's jewellery collection. The company is based in Nuremberg.</div>
			
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={GalerieVoigtHighlights} alt='kristall-baeder-business-card' className='works-image one' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Highlights</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={GalerieVoigtShop} alt='kristall-baeder-logo' className='works-image two' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Shop</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={GalerieVoigtCart} alt='kristall-baeder-magazin' className='works-image three' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Cart</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={GalerieVoigtCheckout} alt='kristall-baeder-online' className='works-image four' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Check out</div>
			</div>



			<p className='sidebar-works d-none d-sm-block'>Galerie Voigt</p>

			<div class="row navigation main">
				<div class="col-6 text-right">
					<a href='/binetiq' className='no-style-anchor'>
						<div className='prev navi'>Previous</div>
						<div className='prev goto' id="no-style-at-all-anchor">Binetiq</div>
					</a>
				</div>
				<div class="col-6 navigation">
					<a href='/mycs' className='no-style-anchor'>
						<div className='next navi'>Next</div>
						<div className='next goto' id="no-style-at-all-anchor" >Mycs</div>
					</a>
				</div>
			</div>

			<Contact />
			
			<div>
				<Footer />
			</div>
		</div >
		</div>
	);
}

export default GalerieVoigt;