import React from 'react';
import '../works.css';
import Header from '../Header.js'
import Contact from '../Contact.js'
import Footer from '../Footer.js'
import mycsMain from '../images/works/mycs/mycs-main.svg'
import mycsCover from '../images/works/mycs/mycs-cover.svg'
import mycsContents from '../images/works/mycs/mycs-contents.svg'
import mycsIntro from '../images/works/mycs/mycs-intro.svg'
import mycsShowrooms from '../images/works/mycs/mycs-showrooms.svg'
import mycsSection from '../images/works/mycs/mycs-section.jpg'
import mycsHighlights from '../images/works/mycs/mycs-room-highlights.svg'
import mycsRoom from '../images/works/mycs/mycs-room.svg'
import mycsDouble from '../images/works/mycs/mycs-double.jpg'
import mycsPictures from '../images/works/mycs/mycs-pictures.svg'
import mycsDetails from '../images/works/mycs/mycs-details.svg'
import mycsInterview from '../images/works/mycs/mycs-interview.jpg'
import mycsInterview2 from '../images/works/mycs/mycs-interview2.jpg'
import mycsInterview3 from '../images/works/mycs/mycs-interview3.jpg'
import mycsHomepage from '../images/works/mycs/mycs-homepage.jpg'
import mycsShelfConfigurator from '../images/works/mycs/mycs-shelf-configurator.jpg'
import mycsTableConfigurator from '../images/works/mycs/mycs-table-configurator.jpg'
import mycsProductExplanation from '../images/works/mycs/mycs-product-explanation.jpg'
import mycsCheckout from '../images/works/mycs/mycs-checkout.jpg'
import mycsBlog from '../images/works/mycs/mycs-blog.jpg'



function Mycs() {
	return (
		<div>
	
			<Header />
			<div class="container-fluid works">
			{/* <!-- Control the column width, and how they should appear on different devices --> */}
			<div class="row intro-works row-no-padding">
				{/* <div class="col-sm-1" ></div> */}
				<div class="col-xl-12 d-block d-sm-none"><div id='category'>E-commerce furniture</div>
					<div id='company'>Mycs</div>
				</div>
				<div class="col-sm-4 d-none d-sm-block" >
					<div id='category'>E-commerce furniture</div>
					<div id='company'>Mycs</div>
					<div className='works-intro-heading'>Role</div>
					<div className='works-intro-details'>Art Director and UI/UX Designer</div>
					<div className='works-intro-heading'>Client</div>
					<div className='works-intro-details'>Mycs</div>
					<div className='works-intro-heading'>Expertise</div>
					<div className='works-intro-details'>Editorial Design, UI/UX Design</div>
					<div className='works-intro-details website'><a href='https://de.mycs.com/' target="blank">See website</a></div>
				</div>
				<div class="col-sm-6 d-none d-sm-block" ><img src={mycsMain} alt='kristall-baeder' className='main-image mycs' /></div>
				<div class="row row-no-padding">
					<div class="col-xl-6 d-block d-sm-none" ><img src={mycsMain} alt='kristall-baeder' className='main-image' /></div>
				</div>
				<div class="col-sm-1 d-block d-sm-none" ></div>
				<div class="col-sm-10 d-block d-sm-none" >
					<div className='works-intro-heading'>Role</div>
					<div className='works-intro-details'>Art Director and UI/UX Designer</div>
					<div className='works-intro-heading'>Client</div>
					<div className='works-intro-details'>Mycs</div>
					<div className='works-intro-heading'>Expertise</div>
					<div className='works-intro-details'>Editorial Design, UI/UX Design</div>
					<div className='works-intro-heading'></div>
					<div className='works-intro-details website'><a href='https://de.mycs.com/' target="blank">See website</a></div>
				</div>
				<div class="col-sm-1 d-block d-sm-none" ></div>
				{/* <div class="col-sm-1" ></div> */}
			</div>
			<div class="row">
				
				<div className="col-xl-12 work-description one">I worked at Mycs for approximately three years, throughout which I was Head of the Design Department, developing its brand concept at a 360° angle.</div>
			
			</div>

			<div class="row row-no-padding">
				<div className="col" align="center"><h2 id="h2">Website</h2></div>
			</div>

			<div class="row">
				<div className="col-xl-12 work-description two">The concept and development of the site were processed by our team of Brand experts, UX researchers, UX/UI designers and IT.</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={mycsHomepage} alt='kristall-baeder-online' className='works-image four' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Homepage</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={mycsShelfConfigurator} alt='kristall-baeder-online' className='works-image four' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Shelf configurator</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={mycsTableConfigurator} alt='kristall-baeder-online' className='works-image four' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Table configurator</div>
			</div>
			
			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={mycsProductExplanation} alt='kristall-baeder-online' className='works-image four' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Product explanation</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={mycsCheckout} alt='kristall-baeder-online' className='works-image four' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Check out</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={mycsBlog} alt='kristall-baeder-online' className='works-image four' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Blog & Logo</div>
			</div>

			<div class="row row-no-padding">
				<div className="col" align="center"><h2 id="h2">Catalogue</h2></div>
			</div>

			<div class="row">
				<div className="col-xl-12 work-description two">Alongside the Brand and Editorial Departments, I devised the concept of the company catalogue and designed the final product layout.</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={mycsCover} alt='kristall-baeder-business-card' className='works-image one' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Cover</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={mycsContents} alt='kristall-baeder-logo' className='works-image two' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Contents</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={mycsIntro} alt='kristall-baeder-magazin' className='works-image three' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Product introduction</div>
			</div>


			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={mycsShowrooms} alt='kristall-baeder-online' className='works-image four' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Mycs showrooms</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={mycsSection} alt='kristall-baeder-online' className='works-image four' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Section intro</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={mycsHighlights} alt='kristall-baeder-online' className='works-image four' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Room product highlights</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={mycsRoom} alt='kristall-baeder-online' className='works-image four' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Room products</div>
			</div>

			
			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={mycsDouble} alt='kristall-baeder-online' className='works-image four' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Double page picture</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={mycsPictures} alt='kristall-baeder-online' className='works-image four' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Pictures</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={mycsDetails} alt='kristall-baeder-online' className='works-image four' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Product details</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={mycsInterview} alt='kristall-baeder-online' className='works-image four' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Interview</div>
			</div>

			
			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={mycsInterview2} alt='kristall-baeder-online' className='works-image four' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Interview</div>
			</div>

			<div class="row row-no-padding">
				<div class="col-12 works-images"><img src={mycsInterview3} alt='kristall-baeder-online' className='works-image four' /></div>
			</div>

			<div class="row">
				<div class="col caption one">Interview</div>
			</div>


			<p className='sidebar-works d-none d-sm-block'>Mycs</p>

			<div class="row navigation main">
				<div class="col-6 text-right">
					<a href='/galerie-voigt' className='no-style-anchor'>
						<div className='prev navi'>Previous</div>
						<div className='prev goto' id="no-style-at-all-anchor">Galerie Voigt</div>
					</a>
				</div>
				<div class="col-6 navigation">
					<a href='/illustrations' className='no-style-anchor'>
						<div className='next navi'>Next</div>
						<div className='next goto' id="no-style-at-all-anchor" >Illustrations</div>
					</a>
				</div>
			</div>

			<Contact />
			
			<div>
				<Footer />
			</div>
		</div >
		</div>
	);
}

export default Mycs;