import React from 'react';
import './App.css';
import Home from './Home.js'
import './index.css'
import { Switch, Route } from 'react-router-dom'
import KristallBeader from './works/KristallBaeder.js'
import Rupp from './works/Rupp.js'
import Binetiq from './works/Binetiq.js'
import GalerieVoigt from './works/GalerieVoigt.js'
import Illustrations from './works/Illustrations.js'
import Amano from './works/Amano.js'
import Mycs from './works/Mycs.js'



function App() {
  return (
    <Switch>
      <Route exact path='/' render={() => (
        <Home />
      )} />

      <Route path='/kristall-baeder' render={() => (
        <KristallBeader />
      )} />

      <Route path='/rupp-hubrach' render={() => (
        <Rupp />
      )} />

      <Route path='/binetiq' render={() => (
        <Binetiq />
      )} />
      
      <Route path='/galerie-voigt' render={() => (
        <GalerieVoigt />
      )} />

      <Route path='/illustrations' render={() => (
        <Illustrations />
      )} />

      <Route path='/amano' render={() => (
        <Amano />
      )} />

      <Route path='/mycs' render={() => (
        <Mycs />
      )} />
      
    </Switch>
  )
}


export default App;
